<template lang="pug">
.modules-row(@click="goto()", v-bind:class="{ active: isActive }")
	img(:src="urlIcon")
	p(:class="{ 'title-6': !isActive, 'title-3': isActive }") {{ $t(label) }}
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
	props: ["urlIcon", "label", "link", "isActive"],

	methods: {
		goto() {
			switch (typeof this.link) {
				case "string":
					this.$router.push(this.link).catch(() => {});
					break;
				case "object":
					if (this.link.cb) {
						this.link.cb();
					}
					break;
			}
			this.$store.commit('mobile-layout/showUserMenu', false)
		},
	},
});
</script>

<style lang="scss" scoped>
.modules-row {
	display: flex;
	align-items: center;
	padding-left: 33px;
	padding-top: 20px;
	&:hover {
		.img-wrapper {
			img {
				filter: var(--svg-filter-primary-color);
			}
		}
		p {
			color: var(--primary-color);
		}
	}
	&.active {
		img {
			filter: var(--svg-filter-primary-color);
		}
		&:before {
			content: "";
			height: 45px;
			width: 45px;
			position: absolute;
			top: 0;
			left: 0px;
		}
		p {
			color: var(--primary-color);
			font-weight: bolder;
		}
	}
	img {
		filter: var(--svg-filter-text-color);
		width: 24px;
		height: 24px;
	}
	p {
		color: var(--text-color);
		padding-left: 25px;
		z-index: 1;
		transition: color 0.25s;
	}
}
</style>